const LOCAL_STORAGE_KEY = 'resources-to-compare';

type CompareListListener = (count: number) => void;

// @ts-ignore
const listeners: Set<CompareListListener> = window.compareListeners || new Set();
// @ts-ignore
window.compareListeners = listeners;

function getResources(): string[] {
    const resources = localStorage.getItem(LOCAL_STORAGE_KEY);
    return resources ? JSON.parse(resources) : [];
}

function setResources(resources: string[]): void {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(resources));
    notifyListeners(resources.length);
}

function notifyListeners(count: number): void {
    listeners.forEach(listener => {
        try {
            listener(count);
        } catch (error) {
            console.error('Error in compare list listener:', error);
        }
    });
}

export function getResourcesToCompare(): string[] {
    return getResources();
}

export function resourceIsInCompare(slug: string): boolean {
    return getResources().includes(slug);
}

export function addResourceToCompare(slug: string): boolean {
    const resources = getResources();
    if (!resources.includes(slug)) {
        resources.push(slug);
        setResources(resources);
        return true;
    }
    return false;
}

export function removeResourceFromCompare(slug: string): boolean {
    const resources = getResources();
    const index = resources.indexOf(slug);
    if (index !== -1) {
        resources.splice(index, 1);
        setResources(resources);
        return true;
    }
    return false;
}

export function addCompareListListener(listener: CompareListListener): void {
    listeners.add(listener);
    listener(getResources().length);
}

export function removeCompareListListener(listener: CompareListListener): void {
    listeners.delete(listener);
}

export function getResourceCount(): number {
    return getResources().length;
}
