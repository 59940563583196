/*! based on https://github.com/jquery/typesense-minibar 1.2.0 */
type Level = {
    snippet: string;
};

type HierarchyHighlight = {
    lvl1: Level[];
    lvl2: Level[];
    lvl3: Level[];
    lvl4: Level[];
    lvl5: Level[];
    lvl6: Level[];
    content: Level[];
};

type HierarchyDocument = {
    lvl1: string[];
};

type HitResponse = {
    title: string;
    url: string;
    content: string;
    thumbnail?: string;
};

type Hit = HitResponse & {
    highlight: {
        hierarchy: HierarchyHighlight;
    };
    document: {
        hierarchy: HierarchyDocument;
        url: string;
        thumbnail?: string;
    };
};

type GroupedHit = {
    found: number;
    group_key: string[];
    hits: Hit[];
};

export function tsminibar(form: HTMLFormElement) {
    const { origin, collection } = form.dataset;
    if (!origin || !collection) {
        return;
    }
    const cache = new Map();
    const state = {
        query: '',
        cursor: -1,
        open: false,
        total: 0,
        groupedHits: [] as GroupedHit[],
    };
    const searchParams = new URLSearchParams({
        per_page: '15',
        query_by:
            'hierarchy.lvl1,hierarchy.lvl2,hierarchy.lvl3,hierarchy.lvl4,hierarchy.lvl5,hierarchy.lvl6,hierarchy.content',
        include_fields:
            'hierarchy.lvl1,hierarchy.lvl2,hierarchy.lvl3,hierarchy.lvl4,hierarchy.lvl5,hierarchy.lvl6,hierarchy.content,group,url,thumbnail,id',
        highlight_full_fields:
            'hierarchy.lvl1,hierarchy.lvl2,hierarchy.lvl3,hierarchy.lvl4,hierarchy.lvl5,hierarchy.lvl6,hierarchy.content',
        group_by: 'group',
        group_limit: '3',
        snippet_threshold: '8',
        highlight_affix_num_tokens: '12',
        'x-typesense-api-key': form.dataset.key,
        ...Object.fromEntries(new URLSearchParams(form.dataset.searchParams)),
    } as Record<string, string>);
    const noResults = form.dataset.noResults || "No results for '{}'.";

    const input: HTMLInputElement | null = form.querySelector('input[type=search]');
    if (!input) {
        return;
    }
    const listbox = document.createElement('div');
    listbox.setAttribute('role', 'listbox');
    listbox.hidden = true;
    input.after(listbox);

    let preconnect: HTMLLinkElement | null = null;
    input.addEventListener('focus', () => {
        if (!preconnect) {
            preconnect = document.createElement('link');
            preconnect.rel = 'preconnect';
            preconnect.crossOrigin = 'anonymous'; // match fetch cors,credentials:omit
            preconnect.href = origin;
            document.head.append(preconnect);
        }
        if (!state.open && state.groupedHits.length) {
            state.open = true;
            render();
        }
    });
    input.addEventListener('input', async () => {
        state.query = input.value;
        const query = input.value;
        if (!query) {
            state.groupedHits = []; // don't leak old hits on focus
            state.cursor = -1;
            return close();
        }
        const hits = (await search(query)) as GroupedHit[];
        const total = hits.reduce((sum, current) => sum + current.found, 0);
        if (state.query === query) {
            // ignore non-current query
            state.groupedHits = hits;
            state.cursor = -1;
            state.open = true;
            state.total = total;
            render();
        }
    });
    input.addEventListener('click', () => {
        if (!state.open && state.groupedHits.length) {
            state.open = true;
            render();
        }
    });
    input.addEventListener('keydown', e => {
        if (!(e instanceof KeyboardEvent) || e.altKey || e.ctrlKey || e.metaKey || e.shiftKey) {
            return;
        }
        switch (e.code) {
            case 'ArrowDown':
                moveCursor(1);
                break;
            case 'ArrowUp':
                moveCursor(-1);
                break;
            case 'Escape':
                close();
                break;
            case 'Enter':
                {
                    let currentIndex = state.cursor;
                    let url = '';
                    for (const groupedHit of state.groupedHits) {
                        if (currentIndex < groupedHit.hits.length) {
                            url = groupedHit.hits[currentIndex]?.url;
                            break;
                        }
                        currentIndex -= groupedHit.hits.length;
                    }
                    if (url) {
                        location.href = url;
                    }
                }
                break;
            default:
                break;
        }
    });
    form.addEventListener('submit', e => {
        e.preventDefault(); // disable fallback
    });
    form.insertAdjacentHTML(
        'beforeend',
        '<svg viewBox="0 0 12 12" width="20" height="20" aria-hidden="true" class="tsmb-icon-close" style="display: none;"><path d="M9 3L3 9M3 3L9 9"/></svg>',
    );
    form.querySelector('.tsmb-icon-close')?.addEventListener('click', () => {
        input.value = '';
        input.focus();
        close();
    });
    connect();

    function close() {
        if (state.open) {
            state.cursor = -1;
            state.open = false;
            render();
        }
    }

    function connect() {
        document.addEventListener('click', onDocClick);
        if (form.dataset.slash !== 'false') {
            document.addEventListener('keydown', onDocSlash);
            form.classList.add('tsmb-form--slash');
        }
    }

    function disconnect() {
        document.removeEventListener('click', onDocClick);
        document.removeEventListener('keydown', onDocSlash);
    }

    function onDocClick(e: MouseEvent) {
        if (!form.contains(e.target as Node)) {
            close();
        }
    }

    function onDocSlash(e: KeyboardEvent) {
        if (e.key === '/' && !/^(INPUT|TEXTAREA)$/.test(document.activeElement?.tagName ?? '')) {
            input?.focus();
            e.preventDefault();
        }
    }

    async function search(query: string) {
        let groupedHits = cache.get(query);
        if (groupedHits) {
            cache.delete(query);
            cache.set(query, groupedHits); // LRU
            return groupedHits;
        }
        searchParams.set('q', query);
        const resp = await fetch(`${origin}/collections/${collection}/documents/search?${searchParams}`, {
            mode: 'cors',
            credentials: 'omit',
            method: 'GET',
        });
        const data = await resp.json();
        groupedHits =
            data?.grouped_hits?.map((ghit: GroupedHit) => {
                const hits = ghit.hits.map((hit: Hit) => {
                    const processedHit: HitResponse = {
                        title: hit.highlight.hierarchy.lvl1?.[0]?.snippet || hit.document.hierarchy.lvl1[0],
                        content:
                            [
                                hit.highlight.hierarchy.lvl2,
                                hit.highlight.hierarchy.lvl3,
                                hit.highlight.hierarchy.lvl4,
                                hit.highlight.hierarchy.lvl5,
                                hit.highlight.hierarchy.lvl6,
                                hit.highlight.hierarchy.content,
                            ]
                                .filter(Boolean)
                                .flat()
                                .map(item => item.snippet)
                                .filter(Boolean)
                                .join(' › ') || hit.document.hierarchy.lvl1[0],
                        url: hit.document.url,
                        thumbnail: hit.document.thumbnail,
                    };
                    return processedHit;
                });

                return { ...ghit, hits };
            }) || [];
        cache.set(query, groupedHits);
        if (cache.size > 100) {
            cache.delete(cache.keys().next().value);
        }
        return groupedHits;
    }

    function escapeFunc(s: string) {
        return s.replace(
            /['"<>&]/g,
            c =>
                ({
                    "'": '&#039;',
                    '"': '&quot;',
                    '<': '&lt;',
                    '>': '&gt;',
                    '&': '&amp;',
                })[c] || '',
        );
    }

    function render() {
        listbox.hidden = !state.open;
        form.classList.toggle('tsmb-form--open', state.open);
        if (state.open) {
            let total = 0;
            listbox.innerHTML =
                state.groupedHits
                    .map(grouped => {
                        const items = grouped.hits
                            .map(
                                (hit, i) =>
                                    `<div role="option"${i + total === state.cursor ? ' aria-selected="true"' : ''}>
                                    <a href="${hit.url}" tabindex="-1">
                                        <div class="tsmb-suggestion_title-wrap">
                                            ${
                                                hit.thumbnail
                                                    ? `<img src=${hit.thumbnail} alt="thumbnail" class="tsmb-suggestion_thumbnail">`
                                                    : ''
                                            }
                                            <div class="tsmb-suggestion_title">${hit.title}</div>
                                        </div>
                                        <div class="tsmb-suggestion_content">${hit.content}</div>
                                    </a>
                                </div>
                            `,
                            )
                            .join('');
                        total += grouped.hits.length;
                        return `<div class="tsmb-suggestion_group">${grouped.group_key[0].replace('-', ' ')}</div>${items}`;
                    })
                    .join('') || `<div class="tsmb-empty">${noResults.replace('{}', escapeFunc(state.query))}</div>`;
        }
    }

    function moveCursor(offset: number) {
        state.cursor += offset;
        // -1 refers to input field
        if (state.cursor >= state.total) {
            state.cursor = -1;
        }
        if (state.cursor < -1) {
            state.cursor = state.total - 1;
        }
        render();
    }

    return { form, connect, disconnect };
}
