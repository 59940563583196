import { getResourcesToCompare, addCompareListListener, removeCompareListListener } from '../utils/compare-resources';

function updateCompareCountInHeader(count: number) {
    const header = document.querySelector('.header-nav-menu-list');
    if (!header) {
        console.error('Header not found');
        return;
    }
    const compareLink = header.querySelector('a[href="/compare"]');
    if (!compareLink) {
        console.error('Compare link not found');
        return;
    }
    const cta = 'Compare tools';
    compareLink.textContent = count > 0 ? `${cta} (${count})` : cta;
}

export function initializeCompareCount() {
    const initialCount = getResourcesToCompare().length;
    updateCompareCountInHeader(initialCount);

    // Add listener for future updates
    addCompareListListener(updateCompareCountInHeader);
}

// Call initializeCompareCount when the DOM is ready
if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initializeCompareCount);
} else {
    initializeCompareCount();
}

// Cleanup function to remove the listener when needed
export function cleanupCompareCount() {
    removeCompareListListener(updateCompareCountInHeader);
}
